<template>
 <section class="guincho-moto" style="min-height:50vh!important;"  v-if="page && page.content">
              <div style="color:#fff!important; display: inline-flex;">
                <RouterLink to="/"><strong>
                    <p>Home</p>
                  </strong></RouterLink>
                <p>&nbsp;-&nbsp;</p>
                <p>{{ decodeURIComponent(this.$route.path).replaceAll('-', ' ').replaceAll('/', ' ').slice(1).charAt(0).toUpperCase() + this.$route.path.replaceAll('-', ' ').replaceAll('/', ' ').slice(2) }}</p>
              </div>
            <h1 v-once>{{ page.content.heading }}</h1>
            <p>{{ page.content.body }}</p>
            <h2>Por que escolher o Guincho para Moto da SOS Motoka?</h2>
    <ul v-once>
      <li><strong>Atendimento 24 horas</strong>: Estamos à disposição a qualquer hora do dia ou da noite para emergências.</li>
      <li><strong>Rapidez no atendimento</strong>: Nosso serviço de guincho imediato prioriza sua segurança, chegando rapidamente ao local.</li>
      <li><strong>Profissionais qualificados</strong>: Nossa equipe é treinada para lidar com qualquer tipo de moto, desde scooters até motocicletas de alta cilindrada.</li>
      <li><strong>Cobertura ampla</strong>: Atuamos em Campinas, Hortolândia, e toda a região metropolitana, oferecendo cobertura nas principais vias e bairros.</li>
    </ul>

    <h2 v-once>Quando acionar o Guincho para Moto?</h2>
    <p>Se você estiver passando por qualquer uma das seguintes situações, entre em contato com a SOS Motoka imediatamente:</p>
    <ul v-once>
      <li>Pane mecânica ou elétrica</li>
      <li>Acidente de trânsito</li>
      <li>Falta de combustível</li>
      <li>Pneus furados</li>
      <li>Necessidade de transporte de moto para oficinas ou eventos</li>
    </ul>

    <h2 v-once>Como funciona o atendimento imediato?</h2>
    <p v-once>
      Ao solicitar o guincho para moto da SOS Motoka, você conta com uma equipe pronta para agir rapidamente.
      Nossa central de atendimento vai localizar o guincho mais próximo do seu local e enviá-lo imediatamente.
      Com o uso de tecnologia avançada de geolocalização, garantimos um tempo de resposta rápido e eficiente.
    </p>

    <p v-once><strong>Não fique na mão!</strong> Salve nosso número agora mesmo e tenha a tranquilidade de saber que pode contar com a SOS Motoka sempre que precisar.</p>
          </section>
</template>
<style async lang="scss" scoped>
*{
  color:#fff
}
.guincho-moto {
  max-width: 800px;
  margin: 0 auto;
  padding:0 20px;
  font-family: Arial, sans-serif;
}

.guincho-moto h1 {
  color: #0549be;
}
*{
  text-align: left;
}
.guincho-moto p {
  text-align: left;
  font-size: 1.2em;
  line-height: 1.5;
}

.guincho-moto h2 {
  margin-top: 20px;
  color: #0549be;
}

.guincho-moto ul {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  list-style-type: disc;
  align-items: start;
  margin: 0;
  padding: 0;
}

.guincho-moto ul li {
  margin-bottom: 10px;
}
</style>
<script async>
import paginas from '@/assets/pages.json'
export default {
  name: 'servico-cps',
  data () {
    return {
      keywords: [],
      searchQuery: '',
      currentUrl: this.$route.path,
      page: []
    }
  },
  computed: {
    filteredKeywords () {
      return paginas.pages.find(page => page.url === this.currentUrl)
    }
  },
  updated () {
    try {
      let currentUrl = window.location.href.replace('www.', '')
      if (currentUrl.charAt(currentUrl.length - 1) !== '/') {
        currentUrl += '/'
      }
      this.page = paginas.pages.find(page => page.url === currentUrl)
      document.title = this.page.meta.title
      document.querySelector('meta[property="og:type"]').setAttribute('content', 'article')
      document.querySelector('meta[property="og:description"]').setAttribute('content', this.page.meta.description)
      document.querySelector('meta[property="og:title"]').setAttribute('content', this.page.meta.title)
      document.querySelector('meta[name="description"]').setAttribute('content', this.page.meta.description)
      document.querySelector('meta[name="keywords"]').setAttribute('content', this.page.meta.keywords)
      document.querySelector('link[rel="canonical"]').setAttribute('href', this.page.meta.canonical)
      document.querySelector('meta[property="article:published_time"]').setAttribute('content', this.page.datePublished)
      document.querySelector('meta[property="article:modified_time"]').setAttribute('content', this.page.dateModified)
    } catch (error) {
      document.title = 'Guincho Zona Leste - Ligue Agora! - ☎ (11) 9 7665-4444'
    }
  },
  mounted () {
    let currentUrl = window.location.href.replace('www.', '')
    if (currentUrl.charAt(currentUrl.length - 1) !== '/') {
      currentUrl += '/'
    }
    this.page = paginas.pages.find(page => page.url === currentUrl)
    try {
      document.title = this.page.meta.title
    } catch (error) {
      document.title = 'Guincho Zona Leste - Ligue Agora! - ☎ (11) 9 7665-4444'
    }
  },
  methods: {
    addStructuredData () {
      const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'LocalBusiness',
        name: this.page.structuredData.name,
        description: this.page.structuredData.description,
        address: {
          '@type': 'PostalAddress',
          streetAddress: this.page.structuredData.address.streetAddress,
          addressLocality: this.page.structuredData.address.addressLocality,
          addressRegion: this.page.structuredData.address.addressRegion,
          postalCode: this.page.structuredData.address.postalCode,
          addressCountry: this.page.structuredData.address.addressCountry
        },
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: this.page.structuredData.contactPoint.telephone,
          contactType: this.page.structuredData.contactPoint.contactType,
          availableLanguage: this.page.structuredData.contactPoint.availableLanguage
        },
        openingHours: this.page.structuredData.openingHours,
        areaServed: this.page.structuredData.areaServed,
        geo: {
          '@type': 'GeoCoordinates',
          latitude: '-23.5678',
          longitude: '-46.4843'
        },
        logo: this.page.structuredData.logo,
        url: this.page.structuredData.url,
        sameAs: this.page.structuredData.sameAs
      }

      const existingScript = document.querySelector('script[type="application/ld+json"]')

      if (!existingScript) {
        // Adiciona o script se não existir
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(structuredData)
        document.head.appendChild(script)
      } else {
        // Atualiza o conteúdo do script existente
        existingScript.textContent = JSON.stringify(structuredData)
      }
    }

  }
}
</script>
